import { register } from 'register-service-worker'

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}firebase-messaging-sw.js`, {
    ready () {
      console.log('L\'application est servie à partir du cache par un service worker.')
    },
    registered (registration) {
      console.log('Le service worker a été enregistré.')
    },
    cached () {
      console.log('Le contenu a été mis en cache pour une utilisation hors ligne.')
    },
    updatefound () {
      console.log('Nouveau contenu en cours de téléchargement.')
    },
    updated (registration) {
      console.log('Un nouveau contenu est disponible ; veuillez actualiser.')
      // Optionnel : afficher une notification ou une bannière à l'utilisateur pour l'informer qu'une mise à jour est disponible.
    },
    offline () {
      console.log('Aucune connexion Internet trouvée. L\'application fonctionne en mode hors ligne.')
    },
    error (error) {
      console.error('Erreur lors de l\'enregistrement du service worker :', error)
    }
  })
}
